import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  BIND_FILE_AFTER_UPLOAD_REQ,
  BIND_FILE_AFTER_UPLOAD_RES,
  DELETE_FILE_REQ,
  DELETE_FILE_RES,
  DELETE_INVOICE_RES,
  EDIT_INVOICE_REQ,
  EDIT_INVOICE_REQ_PATH_PARAMS,
  EDIT_INVOICE_RES,
  GET_ADMIN_FILE_HISTORY_REQ,
  GET_ADMIN_FILE_HISTORY_RES,
  GET_FILE_DOWNLOAD_URL_REQ,
  GET_FILE_DOWNLOAD_URL_REQ_PATH_PARAMS,
  GET_FILE_DOWNLOAD_URL_RES,
  GET_PRESIGNED_URL_REQ,
  GET_PRESIGNED_URL_RES,
  SAVE_INVOICE_REQ,
  SAVE_INVOICE_RES,
  SEND_CUSTOMS_INVOICE_REQ,
  SEND_CUSTOMS_INVOICE_RES,
  SEND_REFUND_INVOICE_REQ,
  SEND_REFUND_INVOICE_RES,
  SEND_USER_INVOICE_REQ,
  SEND_USER_INVOICE_REQ_PATH_PARAMS,
  SEND_USER_INVOICE_RES,
  SINGLE_BIND_FILE_AFTER_UPLOAD_REQ,
  SINGLE_BIND_FILE_AFTER_UPLOAD_RES,
  UPDATE_FILE_DESCRIPTION_REQ,
  UPDATE_FILE_DESCRIPTION_RES,
} from "../../api-interfaces/shipda-api/adminFile";

export const ADMIN_FILE_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_FILE_QUERY" }] as const,

  getFileHistory: ({ bidId, page, perPage }: GET_ADMIN_FILE_HISTORY_REQ) =>
    [
      {
        ...ADMIN_FILE_QUERY_KEY_GEN.all()[0],
        bidId,
        page,
        perPage,
        entity: "fileHistory",
      },
    ] as const,
};

function useGetPresignedURL({
  bidId,
  isPermission,
}: {
  bidId?: number;
  isPermission: boolean;
}) {
  const mutation = useAppMutation<GET_PRESIGNED_URL_REQ, GET_PRESIGNED_URL_RES>(
    {
      requestOptions: {
        method: "post",
        path: isPermission
          ? `/file/${bidId}/permission/upload`
          : `/file/upload`,
        apiType: "ShipdaAdminDefaultNew",
      },
    }
  );

  return { ...mutation };
}

function useGetNoPermissionPresignedURL() {
  const mutation = useAppMutation<GET_PRESIGNED_URL_REQ, GET_PRESIGNED_URL_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/file/upload`,
        apiType: "ShipdaAdminDefaultNew",
      },
    }
  );

  return { ...mutation };
}

function useBindFileAfterUpload() {
  const mutation = useAppMutation<
    BIND_FILE_AFTER_UPLOAD_REQ,
    BIND_FILE_AFTER_UPLOAD_RES
  >({
    requestOptions: {
      method: "put",
      path: "/file/binding",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSingleBindFileAfterUpload() {
  const mutation = useAppMutation<
    SINGLE_BIND_FILE_AFTER_UPLOAD_REQ,
    SINGLE_BIND_FILE_AFTER_UPLOAD_RES
  >({
    requestOptions: {
      method: "put",
      path: "/file/single/binding",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeleteFile(
  props: {
    successModalInfo?: QueryResponseHandlerSuccessModalInfo;
    onSuccess?: () => void;
  } | void
) {
  const { successModalInfo, onSuccess } = props || {};

  const mutation = useAppMutation<DELETE_FILE_REQ, DELETE_FILE_RES>({
    requestOptions: {
      method: "delete",
      path: "/file/delete",
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E0364: {
              messageType: "titleOnly" as const,
              title: "파일을 삭제할 권한이 없습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },

    onSuccess,
  });

  return { ...mutation };
}

function useGetFileDownloadUrl() {
  const mutation = useAppMutation<
    GET_FILE_DOWNLOAD_URL_REQ,
    GET_FILE_DOWNLOAD_URL_RES,
    GET_FILE_DOWNLOAD_URL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ key }) => `file/download/?key=${key}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSaveInvoice() {
  const mutation = useAppMutation<SAVE_INVOICE_REQ, SAVE_INVOICE_RES>({
    requestOptions: {
      method: "post",
      path: "/file/invoice",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditInvoice() {
  const mutation = useAppMutation<
    EDIT_INVOICE_REQ,
    EDIT_INVOICE_RES,
    EDIT_INVOICE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ invoiceId }) => `/file/invoice/${invoiceId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSendUserInvoice() {
  const mutation = useAppMutation<
    SEND_USER_INVOICE_REQ,
    SEND_USER_INVOICE_RES,
    SEND_USER_INVOICE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ invoiceId }) => `/management/${invoiceId}/email/invoice`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSendRefundInvoice({ bidId }: { bidId: number }) {
  const mutation = useAppMutation<
    SEND_REFUND_INVOICE_REQ,
    SEND_REFUND_INVOICE_RES
  >({
    requestOptions: {
      method: "post",
      path: `/management/${bidId}/email/refund`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useSendCustomsInvoice({ bidId }: { bidId: number }) {
  const mutation = useAppMutation<
    SEND_CUSTOMS_INVOICE_REQ,
    SEND_CUSTOMS_INVOICE_RES
  >({
    requestOptions: {
      method: "post",
      path: `/management/${bidId}/email/customs`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateFileDescription(attachmentId: number) {
  const mutation = useAppMutation<
    UPDATE_FILE_DESCRIPTION_REQ,
    UPDATE_FILE_DESCRIPTION_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/file/${attachmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetFileHistory({
  bidId,
  page,
  perPage,
}: GET_ADMIN_FILE_HISTORY_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_FILE_QUERY_KEY_GEN.getFileHistory>,
    GET_ADMIN_FILE_HISTORY_RES
  >({
    queryKey: ADMIN_FILE_QUERY_KEY_GEN.getFileHistory({ bidId, page, perPage }),
    requestOptions: {
      method: "get",
      path: `/file/${bidId}/attachment/history`,
      apiType: "ShipdaAdminDefaultNew",
      params: { page, perPage },
    },
  });

  return { ...queryResult };
}

function useDeleteInvoice(invoiceId: number) {
  const mutation = useAppMutation<unknown, DELETE_INVOICE_RES>({
    requestOptions: {
      method: "delete",
      path: `/management/invoice/${invoiceId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const ADMIN_FILE_QUERY = {
  useGetPresignedURL,
  useBindFileAfterUpload,
  useDeleteFile,
  useGetNoPermissionPresignedURL,
  useGetFileDownloadUrl,
  useSingleBindFileAfterUpload,
  useSaveInvoice,
  useSendUserInvoice,
  useSendRefundInvoice,
  useSendCustomsInvoice,
  useUpdateFileDescription,
  useGetFileHistory,
  useEditInvoice,
  useDeleteInvoice,
};

export default ADMIN_FILE_QUERY;
